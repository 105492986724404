import React from 'react';

import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import ReduxConnect from 'Demora/redux-connect';

import http from 'Demora/http';

import TrackerComp from './TrackerComp';

import alert from 'Demora/modals/alert';

import asyncComponent from 'Demora/AsyncComponent';

import headless from '../../js/utils/headless';

import * as Sentry from '@sentry/react';

import { Helmet } from 'react-helmet';

const Columns = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-admin-admin" */ '../../js/SideBar/Columns'
		)
);

class PreHeader extends React.Component {
	constructor() {
		super();

		this.state = {
			last_update: 0,
			meta: {
				description: '',
				imageURL: '',
				title: '',
				url: '',
			},
		};
	}

	static getDerivedStateFromProps(props, state) {
		const loc = props.location;

		const path = loc.pathname;

		return null;
	}

	componentDidMount() {
		const props = this.props;

		this.unlisten = this.props.history.listen(this.pageChanged);

		this.pageChanged(this.props.location);

		if (!props.user.id) {
			return;
		}

		this.startPusher();

		this.setSentryUser(props.user);

		headless().then((res) => {
			if (res.state === 1 && res.number > 1) {
				http.post('/data/rbsc', {
					a: 79,
					params2: res,
					path: window.location.pathname,
					x: 0,
					y: 0,
				});
			}
		});
	}

	componentDidUpdate(prevProps) {
		const props = this.props;
		const user = props.user;
		if (user.id !== prevProps.user.id) {
			this.startPusher();
			this.setSentryUser(user);
		}
	}

	pageChanged = (loc, action) => {
		if (loc.pathname.match(/^\/_/)) {
			return;
		}

		const meta = {
			title: 'Free Traffic Exchange | Free Advertising | Traffic Ad Bar',
			description:
				'Traffic Ad Bar is a great way to get FREE website traffic and advertising.',
			imageURL: 'https://trafficadbar.com/assets/home_logo.png',
			url: 'https://trafficadbar.com' + loc.pathname,
		};

		if (loc.pathname.match(/^\/login/)) {
			meta.title =
				'Login | Free Traffic Exchange | Free Advertising | Traffic Ad Bar';
		}
		if (loc.pathname.match(/^\/terms/)) {
			meta.title =
				'Terms | Free Traffic Exchange | Free Advertising | Traffic Ad Bar';
		}
		if (loc.pathname.match(/^\/contact/)) {
			meta.title =
				'Contact | Free Traffic Exchange | Free Advertising | Traffic Ad Bar';
		}
		if (loc.pathname.match(/^\/privacy/)) {
			meta.title =
				'Privacy | Free Traffic Exchange | Free Advertising | Traffic Ad Bar';
		}
		if (loc.pathname.match(/^\/signup/)) {
			meta.title =
				'Signup, Join Traffic Ad Bar | Free Traffic Exchange | Free Advertising';
		}

		if (loc.pathname.match(/^\/website_traffic\/home\/user_profile.php/)) {
			meta.title =
				'Targeted website traffic | Free Traffic Exchange | Free Advertising';
			meta.url = 'https://trafficadbar.com' + loc.pathname + '?uid=1';
		}

		this.setState({
			meta,
		});
	};

	setSentryUser = (user) => {
		Sentry.setUser({
			id: user.id,
			username: user.first_name,
			email: user.email,
		});
	};

	startPusher() {
		if (!this.props.liveData.lh) {
			http
				.get('/data/live')
				.then((data) => {
					if (!data.rufP) {
						return;
					}
					this.context.store.dispatch({
						type: 'STORE_LIVE_DATA',
						data: data.live,
					});
				})
				.catch((e) => {
					Sentry.captureException(e);
				});
		}

		this.context.pusher
			.private('USER_' + this.props.user.id)
			.listen('.d1', this.receivedData);

		const today = new Date();

		http
			.post('/data/save_user_details', {
				ww: screen.width,
				wh: screen.height,
				timezone: today.getTimezoneOffset(),
				timezone2: new Date(today.getFullYear(), 0, 1).getTimezoneOffset(),
			})
			.then((data) => {
				if (data.popup) {
					alert('save_user_details', data.popup.title, data.popup.message);
				}
			})
			.catch((e) => {
				Sentry.captureException(e);
			});
	}

	componentWillUnmount() {
		this.unmounted = true;
		this.unlisten();
	}

	receivedData = (d) => {
		const state = this.state;

		let last_update = state.last_update;

		d = d.data;

		if (d.time && d.time > last_update) {
			for (var i in d.data) {
				const data = d.data[i];

				let last_update = this.state.last_update;

				if (
					data.live &&
					data.uid == this.props.user.id &&
					last_update < data.t
				) {
					last_update = data.t;

					delete data.uid;
					delete data.t;

					if (data.live.lt < this.props.liveData.lt) {
						delete data.live.lt;
						delete data.live.lps;
					}

					this.context.store.dispatch({
						type: 'STORE_LIVE_DATA',
						data: data.live,
					});

					if (!this.unmounted) {
						this.setState({
							last_update,
						});
					}
				}
			}
		}
	};

	getURL(props) {
		const loc = props.location;

		const params = new URLSearchParams(loc.search);

		if (loc.pathname.match(/^\/_/)) {
			params.delete('h');
			params.delete('z');
		}

		if (loc.pathname.match(/^\/login/)) {
			params.delete('redi');
		}

		const search = params.toString();

		const host = document.location.origin;

		return host + loc.pathname + '?' + search + loc.hash;
	}

	render() {
		const { description, title, imageURL, url } = this.state.meta;
		return [
			<Helmet key={0}>
				<meta charSet='utf-8' />
				<title>{title}</title>
				<link rel='canonical' href={url} />

				<meta name='description' content={description} />

				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:image' content={imageURL} />
				<meta property='og:url' content={url} />

				<meta name='twitter:title' content={title} />
				<meta name='twitter:description' content={description} />
				<meta name='twitter:image' content={imageURL} />
				<meta name='twitter:card' content='summary_large_image' />
			</Helmet>,
			<Columns key={1} />,
			<TrackerComp getURL={this.getURL} key={2} id='1' />,
		];
	}
}

PreHeader.contextTypes = {
	pusher: PropTypes.object,
};

export default withRouter(
	ReduxConnect(PreHeader, {
		user: 'user',
		liveData: 'liveData',
	})
);
